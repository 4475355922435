import type { FunctionComponent } from "react";

import type { LinkButtonProps } from "scmp-app/components/posties/button";

import { StyledButton } from "./styles";

type Props = Omit<LinkButtonProps, "variant">;

export const RedirectButton: FunctionComponent<Props> = ({
  children,
  className,
  onClick,
  pathname,
  query,
}) => (
  <StyledButton
    className={className}
    pathname={pathname}
    query={query}
    variant="link"
    onClick={onClick}
  >
    {children}
  </StyledButton>
);

RedirectButton.displayName = "RedirectButton";
